const Container = (props) => {
    const { hasGradient, bg, type, alpha, pos, children, className } = props
    var a = alpha
    if (alpha === undefined) { a = 1 }

    const GetColor = (rgb, alpha) => {
        return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha}`
    }

    const GetStyle = (color) => {
        if (!hasGradient)
            return GetColor(color, alpha)

        return `radial-gradient(${GetColor(color, alpha)}) 69%, ${GetColor(color, alpha)}) 69%, ${GetColor(color, 0)}) 71%)`
    }

    const style = { background: GetStyle([255,255,255]) }
    if (bg) style["background"] = GetStyle(bg)  // Custom bg color

    return (
        <div className={pos}>
            {type === "box" && <Box alpha={a} className={className} styles={style}>{children}</Box>}
            {type === "emptybox" && <EmptyBox alpha={a} className={className} styles={style}>{children}</EmptyBox>}
            {type === "circle" && <Circle alpha={a} className={className} styles={style}>{children}</Circle>}
        </div>
    )
}

export default Container


const Box = (props) => {
    const { styles, children, className } = props

    return (
        <div className={`p-2 ${className}`} style={styles}>
            {children}
        </div>
    )
}

const EmptyBox = (props) => {
    const { styles, children, className } = props

    return (
        <div className={`${className}`} style={styles}>
            {children}
        </div>
    )
}

const Circle = (props) => {
    const { styles, children, className } = props

    return (
        <div className={`${className}`} style={styles}>
            {children}
        </div>
    )
}

export { Box, Circle }
