import Title from "../title";
import Button from "../button";
import bg from "../../data/bg-Logo.png"
import NavBar from "../navbar";


function Mind(props) {
    const { images } = props

    return (
        <div id="Mind" className="ctxt-mind max-srceen-height bg-img fstxt-s" style={{backgroundImage: `url(${bg})`}}>
            <NavBar />

            <section id="hero" className="ph5 pv8 light-bg content-center mh-95">
                <Title type="mind"> Tatyana's Altruistic Mindset Education </Title>
                <p className="txt-center">#TheAltrusticMindsetEffect</p>

                <div className="grid gap-1 p-1 col-2 mt3">
                    <img src={images.mindsetA} alt="mindset" className="max-width"/>

                    <Button color="mind" className="auto-margin txt-center ctxt-primary w-maxium-4 fstxt-m" pos="content-center">
                        <h3>Coming Soon!</h3>
                        <ul className="no-bullets">
                            <li>#yanasui&trade;</li> 
                            <li>~ Coaching</li>
                            <li>~ Self-Learning</li>
                            <li>Courses</li>
                        </ul>
                    </Button>

                </div>

            </section>
        </div>
    )
}


export default Mind