

const Title = (props) => {
    const { type, children } = props

    return (
        <div>
            <h1 className={`papyrus fstitle-l txt-center ctxt-${type} pt2`}>T.A.M.E. {type}</h1>
            <h3 className={`fstitle-m txt-center ctxt-${type} pb2`}>{children}</h3>
        </div>
    )
}

export default Title