


const Card = (props) => {
    const { maxWidth, color, children, className } = props

    return (
        <div className={`${color} ctxt-primary p-1 border-radius-sm box-shadow-3 content-center ${className}`} style={{maxWidth: maxWidth + "px"}}>
            {children}
        </div>
    )
}

export default Card