import './App.css';
import AppRouter from "./components/router"


function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  )
}


export default App;