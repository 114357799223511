

const Button = (props) => {
    const { href, color, children, className, pos } = props

    return (
        <a href={href} className={pos}>
            <div className={`${color} btn-${color} content-center ${className} pv2r`}>
            {children}
            </div>
        </a>
    )

}

export default Button