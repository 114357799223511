import React, { useState, useEffect } from 'react';
import logo from '../data/logo-title.png'
import Card from './card.js'


const NavBar = (props) => {
    const [isShown, setIsShown] = useState('hide')
    const [slideAnin, setSlideAnim] = useState("slideOut")
    const [mobileStyle, setMobileStlye] = useState("")
    const [cover, setCover] = useState("none")
    const size = parseFloat(getComputedStyle(document.documentElement).fontSize) * 40 // For 40 Rem media in the CSS file

    useEffect(() => {
        window.addEventListener('scroll', showNav)
        window.addEventListener('resize', HandleResize)
        HandleResize()  
        
        // return () => {
        //     window.removeEventListener('scroll', showNav)
        //     window.removeEventListener('resize', HandleResize)
        // }
    }, [])

    const HandleResize = () => {
        HandleMobile()
    }

    const HandleMobile = () => {
        window.innerWidth < size ? setMobileStlye("full-right") : setMobileStlye("")
        setSlideAnim('slideOut')
        setCover("none")
    }

    const showNav = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY
            windowHeight > 500 ? setIsShown("show") : setIsShown('hide')
        }
    }
    
    const content = (className) => {
        return (
            <div className={className}>
                <div className="content-center p-1">
                    {/* T.A.M.E.@T.I.M.E. */}
                    <a href="/"><img src={logo} alt="T.A.M.E.@T.I.M.E." className="max-width max-height"/></a>
                </div>

                <div className="content-center p-1 align-center">
                    <div className="flex gap-1 txt-center fstxt-m">
                        <a href="/health"><span className="ctxt-primary">Health</span></a>
                        <a href="/mind"><p className="ctxt-primary">Mind</p></a>
                        <a href="/body"><p className="ctxt-primary">Body</p></a>
                        <a href="/life"><p className="ctxt-primary">Life</p></a>
                        <a href="/skin"><p className="ctxt-primary">Skin</p></a>
                        {/* <a href="/about"><p className="ctxt-primary">About</p></a> */}
                    </div>
                </div>


                { window.innerWidth > size ? ( 
                <div className="align-center pr2 flex justify-end">
                    <a href="https://squareup.com/gift/G1FQ2PM9NQSRK/order"><Card color="health" className="txt-center hover fstxt-s mr1">
                        <span>Gift Card</span>
                    </Card></a>
                    <a href="#book-appointment"><Card color="health" className="txt-center hover fstxt-s">
                        <span>Book Now</span>
                    </Card></a>
                </div>
                ): ( 
                    <div style={{display: 'none'}}></div>
                )}
            </div>
        )
    }

    const Slide = () => {
        if (slideAnin === "slideIn") {
            setSlideAnim("slideOut")
            setMobileStlye("full-right slideOut")
            setCover("none")
        } else {
            setSlideAnim("slideIn")
            setMobileStlye("half-right slideIn")
            setCover("block")
        }
    }

    return (
        <div>
            <div className="fixed max-srceen-width max-srceen-height" style={{background: "rgba(0,0,0,0.7)", zIndex: 10, display: cover}}></div>
            <div className={`fixed navbar ${mobileStyle}`}>
                { window.innerWidth < size ? ( // Mobile: 10}}>
                    <div className="skin max-srceen-height half-srceen-width">
                        {content("inline-block line-height-4")}
                        <div className="fixed inline-block" style={{width:"20px", height:"17px", margin:"10px"}} onClick={Slide}>
                            <div style={{width: "100%", height: "5px", backgroundColor:"#296179", borderRadius:"15px"}}></div>
                            <div style={{width: "100%", height: "5px", backgroundColor:"#296179", borderRadius:"15px", marginTop: "1px"}}></div>
                            <div style={{width: "100%", height: "5px", backgroundColor:"#296179", borderRadius:"15px", marginTop: "1px"}}></div>
                        </div>
                    </div>
                ) : ( // Bigger Screens
                    content(`grid skin max-srceen-width ${isShown}`)
                )}
            </div>
        </div>
    )
}


export default NavBar