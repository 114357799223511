import Title from "../title";
import Container from "../containers";
import Card from "../card";
import NavBar from "../navbar";

function Health(props) {
  const { images } = props;

  return (
    <div id="health" className="ctxt-health fstxt-s">
        <NavBar />

        <section id="hero" className="ph5">
            <Title type="health"> Tatyana's Alternative Medicine Effects </Title>

            <div className="grid gap-1 col-2">
                <div className="content-center grid gap-1">
                    <p>
                        An integrated root cause approach to your customized care, combining holistic Asian and Functional medicine principles.
                    </p>

                    <p>Modalities include:</p>

                    <ul className="ph5">
                        <li>Wellness and Preventative Consulting </li>
                        <li className="underline">
                            <a href="#functional" className="ctxt-health">
                            Functional and Western Lab Testing/Analysis
                            </a>
                        </li>
                        <li>
                            Systemic Detoxification (Remove, Replete, Restore Gut Health)
                        </li>
                        <li>
                            Personalized Anti-Inflammatory Nutrition 
                        </li>
                        <li>
                            Acupuncture, Cupping, & PEMF 
                        </li>
                        <li>
                            Custom Tailored Herbal & Nutraceutical Medicine
                        </li>
                        <li>
                            Complementary Alternative Medicine – Aromatherpy 
                        </li>
                        <li>and MORE!</li>
                    </ul>
                </div>

            <img className="auto-margin h-maxium-4" src={images.bed} alt="bed" />
            </div>
        </section>

        <section id="functional" className="health-light mt3 p-1">
            <Container
                alpha={0.8}
                type="box"
                pos="ml1 pt1 pr2 mb1"
                className="wfit-content"
                >
                <h2 className="h2right txt-center fstxt-l papyrus">Functional Medicine</h2>
            </Container>

            <Container type='box'>
                <p>
                    Functional medicine is a patient-centered approach to healthcare that goes beyond just treating symptoms—it focuses on identifying and addressing the root causes of disease. By considering a person's genetic makeup, environment, and lifestyle factors, we create personalized treatment plans aimed at restoring balance and promoting overall wellness.
                </p>
            </Container>

            <Container
                type='box'
                className="grid gap-1 col-2"
            >
                <img src={images.tamepak} alt="functional medicane pack" className="auto-margin w-maxium-4 max-width p-1"/>
                <div>
                    <h2 className="fstxt-m pt2 papyrus">Key Principles of Functional Medicine:</h2>
                    
                    <ul className="no-bullets pt2 ph5">
                        <li className="pb1">
                            <strong>Personalized Care: </strong>
                            We treat each patient as a unique individual, tailoring our approach to their specific genetic, environmental, and lifestyle factors.
                        </li>
                        <li className="pb1">
                            <strong>Holistic Approach: </strong>
                            We believe in treating the whole person—body, mind, and spirit—to achieve optimal health and well-being.
                        </li>
                        <li className="pb1">
                            <strong>Systems Biology: </strong>
                            Viewing the body as interconnected systems, we strive to restore balance for long-term health.
                        </li>
                        <li className="pb1">
                            <strong>Identifying Root Causes: </strong>
                            Rather than just alleviating symptoms, we focus on uncovering and addressing the underlying factors contributing to illness.
                        </li>
                        <li className="pb1">
                            <strong>Integrative Therapies: </strong>
                            We combine the best of conventional medicine with alternative and complementary treatments to support your journey to health.
                        </li>
                        <li>
                            <strong>Patient Empowerment: </strong>
                            We emphasize educating and empowering patients to take an active role in their own health and wellness.
                        </li>
                    </ul>

                </div>
            </Container>

            <Container type='box'>
                <h2 className="underline pv2r fstxt-l papyrus">East Meets West</h2>
                <p className="pv2r">
                    Discover the Synergy of Functional Medicine and Traditional Chinese Medicine (TCM)
                </p>

                <p className="pv2r">
                    Dr. Tatyana believes in combining the best of both worlds—Functional Medicine and Traditional Chinese Medicine (TCM)—to provide you with a holistic approach to health and wellness. Here’s why integrating these two disciplines can benefit you:
                </p>

                <div className="grid gap-1 col-3 p-1">
                    <Container
                        type="box"
                        bg={[41, 97, 121]}
                        alpha={0.3}
                        className="border-radius-sm max-height content-center"
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">Holistic Perspective</h2>

                        <p>Both Functional Medicine and TCM recognize that health is influenced by various factors such as lifestyle, diet, environment, emotions, and genetics. By addressing the whole person, rather than just symptoms, we can uncover underlying imbalances and promote true healing.</p>
                    </Container>

                    <Container
                        type="box"
                        bg={[41, 97, 121]}
                        alpha={0.3}
                        className="border-radius-sm max-height content-center"
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">Root Cause Analysis</h2>
                        Functional Medicine dives deep to identify and treat the root causes of illness, aligning perfectly with TCM’s philosophy of addressing the underlying imbalances causing health issues. Through methods like pulse diagnosis and tongue examination, TCM offers valuable insights into your health that complement modern diagnostic tools.
                    </Container>

                    <Container
                        type="box"
                        bg={[41, 97, 121]}
                        alpha={0.3}
                        className="border-radius-sm max-height content-center"
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">Individualized Treatment</h2>
                        Your health journey is unique, and both Functional Medicine and TCM emphasize personalized treatment plans tailored to your specific needs. From advanced genetic testing and hormone panels in Functional Medicine to the ancient principles of Yin-Yang balance and the Five Elements theory in TCM, we ensure your treatment plan is as unique as you are.
                    </Container>

                    <Container
                        type="box"
                        bg={[41, 97, 121]}
                        alpha={0.3}
                        className="border-radius-sm max-height content-center"
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">Combination of Therapies</h2>
                        By integrating TCM with Functional Medicine, we expand your treatment options to include herbal medicine, acupuncture, dietary therapy, and mind-body practices alongside conventional medical treatments. This comprehensive approach enhances your healing potential and promotes overall wellness.
                    </Container>

                    <Container
                        type="box"
                        bg={[41, 97, 121]}
                        alpha={0.3}
                        className="border-radius-sm max-height content-center"
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">Patient-Centered Care</h2>
                        Empowerment is key in both Functional Medicine and TCM. We encourage you to take an active role in your healing journey, making informed decisions and adopting practices that support your long-term health and well-being.
                    </Container>

                    <Container
                        type="box"
                        bg={[41, 97, 121]}
                        alpha={0.3}
                        className="border-radius-sm max-height content-center"
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">Comprehensive Healthcare</h2>
                        Embrace a blend of modern scientific knowledge and ancient holistic principles at T.A.M.E. TIME. Our integrated approach addresses the root causes of illness, enhances treatment outcomes, and promotes balance in your life.
                    </Container>
                </div>

                <p>
                    Discover the power of Functional Medicine and TCM working together for your health and well-being. Contact us today to embark on your personalized journey to optimal wellness.
                </p>
            </Container>
           
        </section>

        <section id="acupuncture" className="bg-img grid gap-1 p-1 mt3"
            style={{ backgroundImage: `url(${images.acupuncture})` }}
        >
            <Container
            alpha={0.8}
            type="box"
            pos="grid justify-end pt1 pr2"
            className="wfit-content"
            >
            <h2 className="h2right txt-center fstxt-l papyrus">Acupuncture</h2>
            </Container>

            <Container
            type="circle"
            className="half-border-radius wfit-content w-maxium-6 p-7"
            alpha={0.6}
            hasGradient={true}
            >
                <div className="wfit-content">
                    <p className="txt-center p-1 fstxt-m"><strong className="papyrus">Acupuncture: Your Path to Natural Healing</strong></p>
                    <p className="txt-center">
                        Acupuncture uses fine needles placed precisely to activate your body’s natural healing processes. By stimulating nerves and improving blood flow, acupuncture helps: 
                    </p>

                    <ul className="no-bullets ph2 pt1">
                        <li>
                            <strong>• Release Endorphins: </strong>Your body’s natural painkillers
                        </li>
                        <li>
                            <strong>• Enhance Nerve Signals: </strong>To boost self-healing.
                        </li>
                    </ul>

                    <p className="pt2 ph2 pb1">Effective for treating:</p>

                    <ul className="no-bullets ph5">
                        <li><strong>• Pain </strong>(all types)</li>
                        <li className="bold">• Stress & Emotional issues</li>
                        <li className="bold">• Digestive disorders</li>
                        <li className="bold">• Skin disorders</li>
                        <li className="bold">• Cold & Flu</li>
                        <li className="bold">• Preventative Health</li>
                    </ul>

                    <p className="txt-center p-1">
                        Experience the benefits of acupuncture, with or without Chinese herbal formulas, for a healthier, more balanced you! 
                    </p>
                </div>
            </Container>

            <Container
            alpha={0}
            type="emptybox"
            pos="grid justify-end"
            className="w-maxium-6"
            >
                <p className="pb1 fstxt-m">
                    <strong>Acupuncture Precautions</strong>
                </p>
                <p>
                    Acupuncture might not be suitable for everyone. If you have a bleeding disorder (like hemophilia) or are on blood thinners (e.g., warfarin), there's an increased risk of bleeding or bruising. Always consult with your healthcare provider to ensure acupuncture is safe for you.
                </p>
            </Container>
        </section>

        <section id="herbs" className="health-light mt3">
            <Container
            alpha={0.8}
            type="box"
            pos="ml1 pt1 pr2 mb1"
            className="wfit-content"
            >
            <h2 className="h2right txt-center fstxt-l papyrus">Chinese Herbs</h2>
            </Container>

            <div className="grid gap-1 col-2">
                <Container type='box' pos="herb-info">
                    <p className="pb1 fstxt-m">
                        <strong className='papyrus'>Chinese Herbal Medicine: Time-Tested Healing</strong>
                    </p>
                    <p>
                        Chinese herbs have been a cornerstone of Asian medicine for centuries. With over 5,700 substances—including plants, animals, and minerals—formulated into teas, granules, patents, and tinctures, this tradition has evolved into a sophisticated system. Today’s Chinese pharmacopeia includes 1,800 herbs, each classified by their energetic qualities and targeted functions.
                    </p>
                </Container>

                <img
                    className="auto-margin max-width p-1 herb-info"
                    src={images.herbs}
                    alt="herbs"
                />

                <Container type="box" pos="herb-info row-span-2">
                    <p className="pb1">
                        <strong>How It Works:</strong>
                    </p>

                    <ul className="ph5">
                        <li>
                            <strong>Emperor (Chief): </strong>
                            The main ingredient addressing the primary health issue.
                        </li>
                        <li>
                            <strong>Minister (Deputy): </strong>
                            Supports the emperor and targets secondary conditions. 
                        </li>
                        <li>
                            <strong>Assistant: </strong>
                            Has multiple roles—reinforces or moderates other ingredients, and addresses less critical issues. 
                        </li>
                        <li>
                            <strong>Servant (Envoy): </strong>
                            Directs the formula’s action to specific areas and harmonizes the blend.
                        </li>
                    </ul>

                    <p className="pb1 pt1">
                        <strong>Herbal Formulations:</strong>
                    </p>

                    <p>Combining herbs follows six basic interaction modes:</p>
                    
                    <ul className="ph5 pt1 pb1">
                        <li>
                            <strong>Reinforcement: </strong>
                            Enhances therapeutic effects.
                        </li>
                        <li>
                            <strong>Potentiation: </strong>
                            Boosts overall efficacy
                        </li>
                        <li>
                            <strong>Restraint: </strong>
                            Balances or moderates effects. 
                        </li>
                        <li>
                            <strong>Detoxification: </strong>
                            Reduces harmful side effects.
                        </li>
                        <li>
                            <strong>Counteraction: </strong>
                            Neutralizes adverse reactions.
                        </li>
                        <li>
                            <strong>Toxicity: </strong>
                            Manages or minimizes toxic effects. 
                        </li>
                    </ul>

                    <p>
                        Custom formulas are crafted based on these principles to meet individual needs safely and effectively. 
                    </p>
                </Container>
            </div>
        </section>

        <section id="cupping" className="grid gap-1 p-1 col-2-3r mt2">
            <div className="cupping-info">
                <Container
                    alpha={0.1}
                    type="box"
                    pos="ml1 pt1 pr2 mb1 grid justify-center"
                    className="wfit-content"
                    bg={[41, 97, 121]}
                >
                    <h2 className="h2right txt-center fstxt-l papyrus">Cupping</h2>
                </Container>
                {/* Under title */}

                <div className="grid justify-center auto-margin">
                    <p className="pt1"><strong>Benefits of Cupping:</strong></p>
                    <ul className="ph3 pt1">
                        <li>
                            <strong>Respiratory Issues: </strong>
                            Cough, bronchial congestion, asthma
                        </li>
                        <li>
                            <strong>Digestive Health: </strong>
                            Complaints and discomfort
                        </li>
                        <li>
                            <strong>Pain Relief: </strong>
                            Back pain, arthritis, fibromyalgia
                        </li>
                        <li>
                            <strong>Emotional Well-being: </strong>
                            Anxiety, depression, insomnia
                        </li>
                        <li>
                            <strong>Circulation: </strong>
                            Varicose veins, high blood pressure 
                        </li>
                        <li>
                            <strong>Skin Conditions: </strong>
                            Acne, eczema, shingles 
                        </li>
                    </ul>
                </div>
            </div>

            {/* Side info */}
            <Container
            type="box"
            bg={[41, 97, 121]}
            alpha={0.3}
            className="border-radius-sm max-height content-center"
            pos="cupping-info"
            >
                <p className="fstxt-m"><strong className="papyrus">Cupping Therapy: Revitalize and Heal</strong></p>

                <p className="pt1">
                    Cupping is a traditional Chinese medicine technique designed to clear stagnation and boost the flow of qi (vital energy) throughout the body. By creating suction and placing cups on the skin, cupping draws toxins and impurities to the surface, loosens connective tissue, and enhances blood flow, leading to relaxation and improved cell communication. 
                </p>

                <div className="health mt2 mw-8 border-radius-sm" style={{padding:"1px"}}></div>

                <p className="pt2 pb1"><strong>How It Works:</strong></p>

                <p>
                    Research shows that cupping reduces inflammatory cytokines (pain-causing chemicals) and increases healing cytokines. Although cupping may cause temporary bruising or soreness, this typically indicates the successful removal of toxins and stagnation. 
                </p>
            </Container>

            {/* Bottom */}
            <div className="cupping-info col-span-2">
                <img
                    className="auto-margin max-width p-1"
                    src={images.cupping}
                    alt="bed"
                />

                <p><strong>Caution:</strong></p>
                <ul className="ph5 pt1 pb1">
                    <li>
                        <strong>Bleeding Disorders: </strong>
                        Not recommended for those with hemophilia or on anticoagulants.
                    </li>
                    <li>
                        <strong>Skin Conditions: </strong>
                        Avoid areas with active inflammation, burns, infection, or open wounds.
                    </li>
                </ul>
                <p>Experience the benefits of cupping therapy for a revitalized and balanced body.</p>
            </div>
        </section>

        <section id="aromatherapy" className="p-1 mt2 health-light">
            {/* Change this class */}
            <Container type="box" className="aro circle-back">
                <h2 className="relative fstxt-l pb1 papyrus" style={{background: "none"}}>
                    AROMATHERAPY<span class="fstxt-s italic"> Included in any Skin &/or Acupuncture Treatment</span>
                </h2>

                <p className="fstxt-m">
                    <strong className="papyrus">Natural Healing with Essential Oils</strong>
                </p>

                <p>
                    Aromatherapy uses aromatic essential oils derived from plants to promote holistic healing. With roots in ancient China, India, Egypt, and other cultures, it blends art and science to offer both physical and psychological benefits.
                </p>
            </Container>

            <div className="grid gap-1 col-2">
                <img
                    className="max-width p-1 half-border-radius auto-margin"
                    src={images.aromatherapy}
                    alt="herbs"
                />

                <div className="row-span-2">
                    <Container type="box" 
                    bg={[41, 97, 121]}
                    alpha={0.3}
                    pos="auto-margin w-maxium-4 m-1a"
                    className="border-radius-sm content-center">
                        <p><strong>Benefits of Aromatherapy:</strong></p>
                        <ul className="no-bullets bold pt1 pl2">
                            <li>• Manage Pain</li>
                            <li>• Improve Sleep Quality</li>
                            <li>• Reduce stress, Agitation, and Anxiety</li>
                            <li>• Soothe Sore Joints</li>
                            <li>• Treat Headaches and Migraines</li>
                            <li>• Alleviate Chemotherapy Side Effects</li>
                            <li>• Fight Bacteria, Virus, or Fungus</li>
                            <li>• Improve Digestion</li>
                            <li>• Boost Immunity</li>
                        </ul>
                    </Container>

                    <Container type="box"
                    bg={[41, 97, 121]}
                    alpha={0.3}
                    pos="auto-margin w-maxium-25 m-1a"
                    className="border-radius-sm max-height content-center">
                        <p className="bold">Aromatherapy May Help:</p>
                        <ul className="no-bullets bold pt1 pl2">
                            <li>• Asthma</li>
                            <li>• Insomnia</li>
                            <li>• Fatigue</li>
                            <li>• Depression</li>
                            <li>• Inflammation</li>
                            <li>• Peripheral neuropathy</li>
                            <li>• Menstrual Issues</li>
                            <li>• Alopecia</li>
                            <li>• Arthritis</li>
                            <li>• Menopause</li>
                        </ul>
                    </Container>
                </div>

                <Container type="box">
                    <p className="aro-footer">
                        <strong>Note: </strong>While aromatherapy shows promise, scientific research is still limited in areas like Alzheimer’s, Parkinson’s, and heart disease. 
                    </p>
                </Container>
            </div>
        </section>

        <section id="mat" className="bg-img p-1 mt3"
            // style={{ backgroundImage: `url(${images.mat})` }}
        >
            <div className="grid gap-1 col-3">
                <Container
                alpha={0.8}
                type="box"
                pos="ml1 pt1 pr2 mb1 col-span-3"
                className="wfit-content"
                >
                    <h2 className="h2right txt-center fstxt-l papyrus">
                        HEALING MAT <span className="fstxt-s italic"> Can be included in any Skin or Acupuncture Treatment</span>
                    </h2>
                </Container>

                <img className="auto-margin mw-8 col-span-2 border-radius-sm" src={images.mat} alt="mat" />

                <h3 className="col-span-3 txt-center row-start-2 fstxt-m papyrus">
                    Elevate Your Wellness with Advanced Therapies
                </h3>

                <Container
                    type="circle"
                    bg={[41, 97, 121]}
                    pos="auto-margin row-start-3 col-start-1"
                    className="w-maxium-4 p-1 border-radius-sm"
                    alpha={0.2}
                >
                    <p className="fstxt-m pb1">
                        <strong className="papyrus">Pulsed Electromagnetic Field Therapy (PEMF):</strong>
                    </p>
                    <ul className="ph5">
                        <li>
                            <strong>Stimulates Well-being: </strong>
                            Uses electromagnetic fields to enhance overall health.
                        </li>
                        <li>
                            <strong>Neutralizes Electromagnetic Smog: </strong>
                            Helps counteract harmful environmental exposure.
                        </li>
                        <li>
                            <strong>Dr. Oz Featured: </strong>
                            Recognized as a breakthrough in medical technology.
                        </li>
                        <li>
                            <strong>Natural Frequency: </strong>
                            Based on Earth’s 7.83 Hz frequency, aligning with your body’s natural rhythms.
                        </li>
                    </ul>
                </Container>

                <Container
                    type="circle"
                    bg={[41, 97, 121]}
                    alpha={0.2}
                    pos="auto-margin col-start-1"
                    className="w-maxium-4 p-1 border-radius-sm"
                >
                    <p className="fstxt-m pb1">
                        <strong className="papyrus">Far Infrared Therapy:</strong>
                    </p>
                    <ul className="ph5">
                        <li>
                            <strong>Penetrates Deeply: </strong>
                            Far infrared rays reach 4-6 inches into tissues.
                        </li>
                        <li>
                            <strong>Relieves Pain: </strong>
                            Helps reduce pain, inflammation, and stiffness.
                        </li>
                        <li>
                            <strong>Boosts Circulation: </strong>
                            Enhances localized blood flow where applied.
                        </li>
                    </ul>
                </Container>

                <Container
                    type="circle"
                    bg={[41, 97, 121]}
                    alpha={0.2}
                    pos="auto-margin"
                    className="w-maxium-4 p-1 border-radius-sm"
                >
                    <p className="fstxt-m pb1">
                        <strong className="papyrus">Negative Ion Therapy:</strong>
                    </p>
                    <ul className="ph5">
                        <li>
                            <strong>Nature’s Purifiers: </strong>
                            Cleans the air of harmful agents and counteracts positive ion disturbances.
                        </li>
                        <li>
                            <strong>Detoxifies: </strong>
                            Attracts airborne particles and aids in waste removal.
                        </li>
                        <li>
                            <strong>Enhances Wellness: </strong>
                            Improves mind and body quality through natural ion release.
                        </li>
                    </ul>
                </Container>

                <Container
                    type="circle"
                    bg={[41, 97, 121]}
                    alpha={0.2}
                    pos="auto-margin"
                    className="w-maxium-4 p-1 border-radius-sm"
                >
                    <p className="fstxt-m pb1">
                        <strong className="papyrus">Hot Stone Therapy:</strong>
                    </p>
                    <ul className="ph5">
                        <li>
                            <strong>Muscle Relaxation: </strong>
                            Heated stones soothe muscles and relieve tension.
                        </li>
                        <li>
                            <strong>Reduces Stress: </strong>
                            Linked to lower levels of stress and anxiety.
                        </li>
                        <li>
                            <strong>Enhances Massage: </strong>
                            Makes the body more receptive to massage therapy.
                        </li>
                        <li>
                            <strong>Amplifies Other Therapies: </strong>
                            Stones release far infrared rays and negative ions, boosting the effectiveness of additional therapies.
                        </li>
                    </ul>
                </Container>

                <p className="col-span-3 txt-center">
                    Experience holistic healing with our HEALING MAT, combining these advanced therapies to support and rejuvenate your body and mind.
                </p>
            </div>
        </section>

    </div>
  );
}

export default Health;